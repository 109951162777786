<!-- 时间表显示条-->
<template>
  <div id="d1">
    <canvas :ref="id" :id="id"></canvas>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    data: {
      type: Object
    },
    id: {
      type: String
    },
    day:{
      type: String,
      default:''
    },
    name:{
      type: String,
      default:''
    }
  },
  data() {
    return {
      show: [],
    };
  },
  // 获取父组件的传参data数据修改成show数组
  watch: {
    data: {
      handler(newVal, oldVal) {
        const _data = JSON.stringify(newVal)
        const data = JSON.parse(_data)
        for (let i in data) {
          let begin1 = data[i].begin[0]
          let begin2 = data[i].begin[1]
          let end1 = data[i].end[0]
          let end2 = data[i].end[1]
          let state = data[i].model
          if(begin1 == '--' || begin2 == '--' || end1 == '--' || end2 == '--'){
          }else{
            this.drawingTime(begin1,begin2,end1,end2,state)
          }
        }
      },
      immediate: true,
      deep: true,
    },
    // 动态获取data中show的数据动态加载
    show: {
      handler(newVal, oldVal) {
        const _data = JSON.stringify(newVal)
        const data = JSON.parse(_data)
        for (let i = 0; i < data.length; i++) {
          let start = data[i].begin;
          let end = data[i].end;
          let state = data[i].state;
          if (start !== '' && end !== '') {
            let _start = ((start.minute() + start.hour() * 60) / 4 - 90)*Math.PI/180;
            let _end = ((end.minute() + end.hour() * 60) / 4 - 90)*Math.PI/180;
            let _state =
              state == 0
                ? "#0FCE0F"
                : state == 1
                ? "#f8b62d"
                : state == 2
                ? "#eeee00"
                : state == 3
                ? "#7682ce"
                : state == 4
                ? "#00a0e9"
                : "";
            let myCanvas = this.$refs[this.id];
            let ctx = myCanvas.getContext("2d");
            ctx.beginPath();
            ctx.lineWidth = 20;
            ctx.strokeStyle = _state;
            ctx.arc(150, 130, 95, _start, _end);
            ctx.stroke();
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    moment,
    // 绘画时间段
    drawingTime(begin1,begin2,end1,end2,state){
      const _begin1 = Number(begin1) 
      const _begin2 = Number(begin2)
      const _end1 = Number(end1)
      const _end2 = Number(end2)
      const _start = ((_begin1 * 60 + _begin2) / 4 - 90) * Math.PI / 180
      const _end = ((_end1 * 60 + _end2) / 4 - 90) * Math.PI / 180
      let _state = state == 0 ? "#0FCE0F" : state == 1 ? "#f8b62d" : state == 2 ? "#eeee00" : state == 3 ? "#7682ce" : state == 4 ? "#00a0e9" : ""
      let myCanvas = this.$refs[this.id];
      let ctx = myCanvas.getContext("2d");
      ctx.beginPath();
      ctx.lineWidth = 20;
      ctx.strokeStyle = _state;
      ctx.arc(150, 130, 95, _start, _end);
      ctx.stroke();
    },
    // 创建轴线
    axis() {
      let myCanvas = this.$refs[this.id];
      let ctx = myCanvas.getContext("2d");
      myCanvas.width = 300;
      myCanvas.height = 260;
      ctx.beginPath();
      ctx.lineWidth = 20;
      ctx.strokeStyle = "#0FCE0F";
      ctx.arc(150, 130, 95, 0, 2 * Math.PI);
      ctx.stroke();
    },
    // 创建轴线刻表
    lineTo() {
      let myCanvas = this.$refs[this.id];
      let ctx = myCanvas.getContext("2d");
      ctx.beginPath();
      ctx.strokeStyle = "#c7caca";
      ctx.lineWidth = 3;
      ctx.moveTo(150, 45);
      ctx.lineTo(150, 60);
      ctx.moveTo(220, 130);
      ctx.lineTo(235, 130);
      ctx.moveTo(150, 200);
      ctx.lineTo(150, 215);
      ctx.moveTo(65, 130);
      ctx.lineTo(80, 130);
      ctx.stroke();
      ctx.font = "15px Arial";
      ctx.fillText("00:00", 130, 20);
      ctx.fillText("06:00", 260, 135);
      ctx.fillText("12:00", 130, 250);
      ctx.fillText("18:00", 3, 135);
    },
    // 创建时间显示
    hint() {
      let myCanvas = this.$refs[this.id]
      let ctx = myCanvas.getContext("2d")
      ctx.font = "20px Arial"
      ctx.textAlign = 'center'
      ctx.fillText(this.name, 150, 120)
      ctx.fillText(this.day, 153, 150)
    },
  },
  mounted() {
    this.axis();
    this.lineTo();
    this.hint();
  },
};
</script>

<style scoped>
#d1{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>